import React, { useState } from 'react'
import mary from '../assets/images/MariOptimizada.png';
// import { Link } from 'react-router-dom';
import { Button } from 'antd';
import WebChat from './chat/webChat';
// import ModalCerrarChat from '../modal/ModalCerrarChat';

const Asistente = () => {

    const [mostrarBotones, setMostrarBotones] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false)


    const openDrawer = () => {
        console.log('Abriendo drawer');
        setIsDrawerOpen(true);
        setMostrarBotones(false); // Opcional: cerrar los botones al abrir el drawer
    };

    const closeDrawer = () => {
        console.log('Cerrando drawer');
        setIsDrawerOpen(false);
    };

    const toggleBotones = () => {
        setMostrarBotones(!mostrarBotones);
    };

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className='lfr-layout-structure-item-mary lfr-layout-structure-item-d127bdb1-dd87-adf3-3283-e152e6ec2eeb '>
                <div id='fragment-c1636408-3396-659a-d6f5-e30ee31367e7'>
                    <section className='c-c-mary'>
                        <div className='dropdown c-c-mary__container'>
                            <button className='ml-auto dropdown-toggle c-c-mary__logo' type="button" id="mary" aria-haspopup="true" aria-expanded="true" onClick={toggleBotones}>
                                <span class="sr-only">Mary asistente</span>
                                <span class="c-c-mary__logo-text">
                                    <span className='c-c-mary__logo-text-container'>
                                        <span class="c-c-mary__text show" style={{ width: 0 }}> ¡Hola! </span>
                                        <span class="c-c-mary__text-hover" style={{ width: 0 }}>  ¿Necesitas ayuda?  </span>
                                        <span class="c-c-mary__text-active" style={{ width: '100%' }}>  Hablemos  </span>
                                    </span>
                                </span>
                                <span className='c-c-mary__mary'>
                                    <svg class="c-c-mary__mary-bg" xmlns="http://www.w3.org/2000/svg" width="62" height="70" viewBox="0 0 62 70" fill="none"><path d="M59.6627 16.359L32.8399 0.872952C31.9051 0.334016 30.3742 0.334016 29.4394 0.872952L24.1168 3.94441L2.61184 16.359C1.67704 16.9027 0.90918 18.2238 0.90918 19.3065V50.2786C0.90918 51.3612 1.67704 52.6871 2.61184 53.226L29.4394 68.7121C30.3694 69.251 31.9051 69.251 32.8399 68.7121L59.6627 53.226C60.6023 52.6871 61.3654 51.3612 61.3654 50.2786V19.3065C61.3606 18.2238 60.6023 16.9027 59.6627 16.359ZM55.3751 42.6095L38.9495 59.0351C38.3771 59.6074 37.2707 59.9079 36.4885 59.6933L14.0487 53.6839C13.2666 53.474 12.451 52.6585 12.2411 51.8811L6.227 29.4413C6.02192 28.6591 6.31762 27.5527 6.88994 26.9803L23.3156 10.5547C23.8879 9.97763 24.9944 9.68193 25.7813 9.89178L43.7713 14.7088L48.2258 15.9059C49.0032 16.1158 49.814 16.9266 50.0239 17.7087L56.0333 40.1485C56.2526 40.9307 55.9522 42.0324 55.3751 42.6095Z" fill="#FEC82F"></path> <path d="M40.3517 20.5314C40.0798 20.0641 39.4169 19.6777 38.8779 19.6777H23.3919C22.8529 19.6777 22.19 20.0641 21.9181 20.5314L15.7085 31.2815L14.1727 33.9428C13.9056 34.4102 13.9056 35.1781 14.1727 35.6455L21.9181 49.0521C22.1852 49.5195 22.8529 49.9058 23.3919 49.9058H38.8779C39.4169 49.9058 40.0798 49.5243 40.3517 49.0521L48.0971 35.6455C48.3641 35.1781 48.3641 34.4102 48.0971 33.9428L40.3517 20.5314ZM38.6919 38.6645C38.6919 38.9316 38.4964 39.2654 38.2674 39.399L31.5617 43.2717C31.3281 43.4052 30.9417 43.4052 30.708 43.2717L24.0071 39.399C23.7734 39.2654 23.5779 38.9316 23.5779 38.6645V30.9238C23.5779 30.652 23.7734 30.3229 24.0071 30.1894L29.3869 27.0845L30.7128 26.3167C30.9465 26.1831 31.3328 26.1831 31.5665 26.3167L38.2722 30.1941C38.5059 30.3277 38.6967 30.6615 38.6967 30.9286V38.6645H38.6919Z" fill="#FEC82F"></path>
                                    </svg>
                                    <img src={mary} alt='bot' style={{ width: 100, height: 108 }} />
                                </span>
                            </button>
                            <div className='c-c-mary__content' aria-labelledby="mary" role="menu">
                                <div className='c-c-mary__content_container d-flex flex-column'>
                                    {mostrarBotones && (
                                        <>
                                            {/*<Link to='/chat' className='c-c-mary__item' target='_blank'>
                                    <svg aria-hidden="true" width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="message-text">
                                        <path id="Vector" d="M17.92 1.75H3.92004C3.45592 1.75 3.0108 1.93437 2.68261 2.26256C2.35442 2.59075 2.17004 3.03587 2.17004 3.5V19.25L5.67004 15.75H17.92C18.3842 15.75 18.8293 15.5656 19.1575 15.2374C19.4857 14.9092 19.67 14.4641 19.67 14V3.5C19.67 3.03587 19.4857 2.59075 19.1575 2.26256C18.8293 1.93437 18.3842 1.75 17.92 1.75ZM5.67004 7.875H16.17V9.625H5.67004M12.67 12.25H5.67004V10.5H12.67M16.17 7H5.67004V5.25H16.17" fill="#30333A"></path> 
                                        </g>
                                    </svg>  
                                </Link>*/}
                                            <Button className='c-c-mary__item' style={{ backgroundColor: '#008296 !important' }} onClick={openDrawer}>
                                                <svg aria-hidden="true" width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="whatsapp">
                                                    <path id="Vector" d="M17.92 1.75H3.92004C3.45592 1.75 3.0108 1.93437 2.68261 2.26256C2.35442 2.59075 2.17004 3.03587 2.17004 3.5V19.25L5.67004 15.75H17.92C18.3842 15.75 18.8293 15.5656 19.1575 15.2374C19.4857 14.9092 19.67 14.4641 19.67 14V3.5C19.67 3.03587 19.4857 2.59075 19.1575 2.26256C18.8293 1.93437 18.3842 1.75 17.92 1.75ZM5.67004 7.875H16.17V9.625H5.67004M12.67 12.25H5.67004V10.5H12.67M16.17 7H5.67004V5.25H16.17" fill="#ffffff"></path>
                                                </g>
                                                </svg>
                                            </Button>
                                            <button className='c-js-mary__close c-c-mary__close' onClick={toggleBotones}>
                                                <span class="c-c-mary__close-container">
                                                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.46063 4.29357C4.64816 4.1061 4.90247 4.00078 5.16763 4.00078C5.4328 4.00078 5.68711 4.1061 5.87463 4.29357L10.1676 8.58657L14.4606 4.29357C14.5529 4.19806 14.6632 4.12188 14.7852 4.06947C14.9072 4.01706 15.0385 3.98947 15.1712 3.98832C15.304 3.98717 15.4357 4.01247 15.5586 4.06275C15.6815 4.11303 15.7931 4.18728 15.887 4.28117C15.9809 4.37507 16.0552 4.48672 16.1055 4.60962C16.1557 4.73251 16.181 4.86419 16.1799 4.99697C16.1787 5.12975 16.1511 5.26097 16.0987 5.38297C16.0463 5.50498 15.9701 5.61532 15.8746 5.70757L11.5816 10.0006L15.8746 14.2936C16.0568 14.4822 16.1576 14.7348 16.1553 14.997C16.153 15.2592 16.0479 15.51 15.8625 15.6954C15.677 15.8808 15.4262 15.986 15.164 15.9882C14.9018 15.9905 14.6492 15.8897 14.4606 15.7076L10.1676 11.4146L5.87463 15.7076C5.68603 15.8897 5.43343 15.9905 5.17123 15.9882C4.90903 15.986 4.65822 15.8808 4.47281 15.6954C4.28741 15.51 4.18224 15.2592 4.17996 14.997C4.17768 14.7348 4.27848 14.4822 4.46063 14.2936L8.75363 10.0006L4.46063 5.70757C4.27316 5.52004 4.16785 5.26573 4.16785 5.00057C4.16785 4.73541 4.27316 4.4811 4.46063 4.29357Z" fill="#25282D"></path>
                                                    </svg> </span>
                                            </button>
                                            {/* <Link className='c-js-mary__close c-c-mary__close' onClick={toggleBotones}>
                                    <span class="c-c-mary__close-container">
                                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.46063 4.29357C4.64816 4.1061 4.90247 4.00078 5.16763 4.00078C5.4328 4.00078 5.68711 4.1061 5.87463 4.29357L10.1676 8.58657L14.4606 4.29357C14.5529 4.19806 14.6632 4.12188 14.7852 4.06947C14.9072 4.01706 15.0385 3.98947 15.1712 3.98832C15.304 3.98717 15.4357 4.01247 15.5586 4.06275C15.6815 4.11303 15.7931 4.18728 15.887 4.28117C15.9809 4.37507 16.0552 4.48672 16.1055 4.60962C16.1557 4.73251 16.181 4.86419 16.1799 4.99697C16.1787 5.12975 16.1511 5.26097 16.0987 5.38297C16.0463 5.50498 15.9701 5.61532 15.8746 5.70757L11.5816 10.0006L15.8746 14.2936C16.0568 14.4822 16.1576 14.7348 16.1553 14.997C16.153 15.2592 16.0479 15.51 15.8625 15.6954C15.677 15.8808 15.4262 15.986 15.164 15.9882C14.9018 15.9905 14.6492 15.8897 14.4606 15.7076L10.1676 11.4146L5.87463 15.7076C5.68603 15.8897 5.43343 15.9905 5.17123 15.9882C4.90903 15.986 4.65822 15.8808 4.47281 15.6954C4.28741 15.51 4.18224 15.2592 4.17996 14.997C4.17768 14.7348 4.27848 14.4822 4.46063 14.2936L8.75363 10.0006L4.46063 5.70757C4.27316 5.52004 4.16785 5.26573 4.16785 5.00057C4.16785 4.73541 4.27316 4.4811 4.46063 4.29357Z" fill="#25282D"></path>
                                    </svg> </span> 
                            </Link> */}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <WebChat isOpen={isDrawerOpen} onClose={closeDrawer} />
            </div>

        </>
    )
}

export default Asistente

