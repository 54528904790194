import React, { useEffect, useRef, useState } from 'react';
import './WebChatComponent.css';
import ReactWebChat, {
  Components,
  createDirectLine,
  createStore,
  createStyleSet,
  renderWebChat,
  renderMarkdown,

} from 'botframework-webchat';

import { Button, Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';

const { TextContent, Bubble, SuggestedActions, AdaptiveCardContent, HeroCardContent } =
  Components;

/**
 * @typedef {import('botframework-directlinejs').DirectLine} DirectLine
 */

const WebChatComponent = ({ userData, setRestartChat, restartChat }) => {
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [remainingChars, setRemainingChars] = useState(256);
  const webChatRef = useRef(null);
  const directLineRef = useRef(
    /**
     * @type {DirectLine}
     */
    (null)
  );

  const [handOffFlag, setHandOffFlag] = useState(false)

  useEffect(() => {
    if (restartChat) {
      setRestartChat(false)

      const styleOptions = {
        // ROOT
        rootHeight: '100%',
        rootWidth: '100%',
        rootZIndex: 0,


        // Bubbles
        botAvatarImage: 'https://avcolmenabp.com:9443/widget/static/media/mari_bot.b339aea9.jpg',
        botAvatarInitials: 'Bot',
        userAvatarImage: 'https://avcolmenabp.com:9443/widget/static/media/client.3bc8221f.png',
        userAvatarInitials: 'Tú',
        bubbleBackground: '#F4F4F4',
        bubbleBorderColor: '#F4F4F4',
        bubbleBorderRadius: 16,
        bubbleBorderWidth: 2,
        bubbleNubOffset: 0,
        bubbleNubSize: 5,
        bubbleTextColor: '#0097ae',
        bubbleTextSize: '8sp',
        bubbleFromUserBackground: '#F4F4F4',
        bubbleFromUserBorderColor: '#F4F4F4',
        bubbleFromUserBorderRadius: 16,
        bubbleFromUserBorderWidth: 2,
        bubbleFromUserNubOffset: 0,
        bubbleFromUserNubSize: 5,
        bubbleFromUserTextSize: 14,
        bubbleFromUserTextColor: '#0097ae',
        groupTimestamp: 3000,
        showAvatarInGroup: 'status',

        //Suggested actions
        suggestedActionBorderRadius: 50,
        suggestedActionHeight: 30,
        suggestedActionImageHeight: 20,
        suggestedActionLayout: 'stacked',

        suggestedActionBackgroundColor: '#ACACAC',
        suggestedActionBorderColor: '#ACACAC',
        suggestedActionBorderStyle: 'solid',
        suggestedActionBorderWidth: 2,
        suggestedActionTextColor: 'White',

        suggestedActionBackgroundColorOnActive: '#ACACAC',
        suggestedActionBorderColorOnActive: '#ACACAC',
        suggestedActionBorderStyleOnActive: '3px',
        suggestedActionBorderWidthOnActive: '#ACACAC',
        suggestedActionTextColorOnActive: 'White',

        suggestedActionBackgroundColorOnDisabled: undefined,
        suggestedActionBorderColorOnDisabled: '#E6E6E6',
        suggestedActionBorderStyleOnDisabled: undefined,
        suggestedActionBorderWidthOnDisabled: undefined,
        suggestedActionTextColorOnDisabled: undefined,

        suggestedActionBackgroundColorOnFocus: '#ACACAC',
        suggestedActionBorderColorOnFocus: '#ACACAC',
        suggestedActionBorderStyleOnFocus: '3px',
        suggestedActionBorderWidthOnFocus: '#ACACAC',
        suggestedActionTextColorOnFocus: 'White',

        suggestedActionBackgroundColorOnHover: '#ACACAC',
        suggestedActionBorderColorOnHover: '#ACACAC',
        suggestedActionBorderStyleOnHover: '3px',
        suggestedActionBorderWidthOnHover: '#ACACAC',
        suggestedActionTextColorOnHover: 'White',

        suggestedActionKeyboardFocusIndicatorBorderColor: 'Gray',
        suggestedActionKeyboardFocusIndicatorBorderRadius: 0,
        suggestedActionKeyboardFocusIndicatorBorderStyle: 'solid',
        suggestedActionKeyboardFocusIndicatorBorderWidth: 1,
        suggestedActionKeyboardFocusIndicatorInset: 2,
      };


      (async function () {
        try {
          const tokenEndpointURL = new URL(process.env.REACT_APP_TOKEN);
          const locale = 'es';
          const apiVersion = tokenEndpointURL.searchParams.get('api-version');

          let lastBotMessageActivityId = null;
          let handOffFlagAux = false;

          const [directLineURL, token] = await Promise.all([
            fetch(new URL(`/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`, tokenEndpointURL))
              .then(response => {
                if (!response.ok) {
                  throw new Error('Failed to retrieve regional channel settings.');
                }
                return response.json();
              })
              .then(({ channelUrlsById: { directline } }) => directline),
            fetch(tokenEndpointURL)
              .then(response => {
                if (!response.ok) {
                  throw new Error('Failed to retrieve Direct Line token.');
                }
                return response.json();
              })
              .then(({ token }) => token),
          ]);

          directLineRef.current = createDirectLine({
            domain: new URL('v3/directline', directLineURL),
            token,
          });

          const addClientToQueue = (conversationId, clientData) => {
            const url = process.env.REACT_APP_URL_BACKEND + "/clientsQueue"

            const payload = {
              ...clientData, conversationId: conversationId,

            }

            fetch(url, {
              method: "POST",
              mode: 'cors',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'

              },
              body: JSON.stringify(payload)
            })
              .then(val => console.log("Resultado agregar cliente a cola", val))
              .catch(e => console.error(e))

          }

          const chatStore = createStore({}, ({ dispatch }) => next => action => {
            // console.log("Store - Action dispatched", action)

            if (handOffFlagAux) {

              if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
                const { activity } = action.payload;

                if (activity?.type === "message" && activity?.from?.role === "bot") {
                  return;
                }
                return next(action)
              }
            }


            //Se valida que se haya establecido la conexion
            if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
              console.log('Connected:', action.type);

              // const startConversationEvent = {
              //   type: 'event',
              //   name: 'startConversation',
              //   value: {
              //     locale: locale,
              //     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              //     userData: userData
              //   },
              //   from: { id: 'user' }
              // };
              // console.log('Enviando evento de inicio de conversación:', startConversationEvent);

              dispatch({
                type: "WEB_CHAT/SEND_EVENT",
                payload: {
                  name: "startConversation",
                  value: {
                    locale: locale,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    userDataContext: JSON.stringify(userData)
                  }
                }
              });

              setIsInputDisabled(false);

            } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
              if (action.payload.activity.from.role === 'bot') {
                lastBotMessageActivityId = action.payload.activity.id;
                const hasSuggestedActions =
                  action.payload.activity.suggestedActions &&
                  action.payload.activity.suggestedActions.actions &&
                  action.payload.activity.suggestedActions.actions.length > 0;
                setIsInputDisabled(hasSuggestedActions);
              }
            } else if (action.type === 'DIRECT_LINE/QUEUE_INCOMING_ACTIVITY') {
              if (action.payload.activity.from.role === 'bot') {
                const hasSuggestedActions =
                  action.payload.activity.suggestedActions &&
                  action.payload.activity.suggestedActions.actions &&
                  action.payload.activity.suggestedActions.actions.length > 0;
                setIsInputDisabled(hasSuggestedActions);
              }
              //validación de evento "handoff"
              // validateHandoff(action.payload.activity, chatStore)
              const activity = action.payload?.activity;
              if (activity.type === "event") {
                if (handOffFlagAux === false && activity.name === "handoff.initiate") {
                  console.log("Se detecta evento de inicio de handoff", activity)
                  console.log("Data to send", activity.value)
                  // const dataToSend={"apellidos":"Solano Rodríguez","canal":"web","departamento":"Tolima","email":"jaimetaylos@gmail.com","nombres":"Ana María","numeroIdentificacion":"1098822189","region":"Ibagué","split":"Tramites Administrativos","telefono":"57 3178946787","tipoIdentificacion":"Cédula ciudadanía","conversationId":"3Gh527HywHT32meyZF1tJt-us"}
                  addClientToQueue(activity.conversation.id, activity.value)
                  handOffFlagAux = true

                }
                if (handOffFlagAux === true && activity.name === "handoff.close") {
                  console.log("Se detecta evento de cierre de handoff", activity)
                  handOffFlagAux = false
                  setRestartChat(true)

                }
              }


            } else if (action.type === 'WEB_CHAT/SET_SUGGESTED_ACTIONS') {
              const hasSuggestedActions =
                action.payload.suggestedActions && action.payload.suggestedActions.length > 0;
              setIsInputDisabled(hasSuggestedActions);
            }


            return next(action);
          })
          const customAttachmentMiddleware = () => (next) => (card) => {
            const { activity, attachment } = card;
            console.log("Activity", card)


            if (
              activity.type === "message" &&
              activity.from.role === "bot"
            ) {
              const { TextContent, Bubble, SuggestedActions, HeroCardContent } =
                Components;

              let imageAttachments = activity.attachments.filter(
                (attachment) =>
                  attachment.contentType ===
                  "application/vnd.microsoft.card.hero" ||
                  attachment.contentType ===
                  "application/vnd.microsoft.card.adaptive"
              );

              const bubbles = [];

              if (activity.text) {

                const textContent = next(card)
                bubbles.push(textContent)

                // bubbles.push(
                //   <Bubble key="text" fromUser={false} nub={true}>
                //     <TextContent
                //       contentType={activity.textFormat}
                //       activity={activity}
                //       text={textContent}
                //     // text={activity.text}
                //     />
                //   </Bubble>
                // );
              }

              if (imageAttachments.length > 0) {
                imageAttachments.forEach((attachment, index) => {
                  const imageUrl = attachment.content?.images?.[0]?.url;
                  if (imageUrl) {
                    bubbles.push(
                      <Bubble key={`image-${index}`} fromUser={false} nub={true}>
                        <img
                          src={imageUrl}
                          alt={`attachment-${index}`}
                          style={{ width: "100%", marginBottom: "10px" }}
                        />
                      </Bubble>
                    );
                  }
                });
              }

              if (activity.suggestedActions?.actions?.length > 0 && lastBotMessageActivityId === activity.id) {
                bubbles.push(
                  <Bubble
                    key="suggestedActions"
                    fromUser={false}
                    nub={true}
                  >
                    <SuggestedActions className="acciones-sugeridas" />
                  </Bubble>
                );
              }

              return bubbles;
            }

            return next(card);
          };



          const attachmentMiddleware = () => next => (card) => {
            const { activity, attachment } = card
            // console.log("card", card)

            //deteccion de ultimo mensaje del bot
            const { activities } = chatStore.getState();
            const messageActivities = activities.filter(activity => activity.type === 'message');
            const recentBotMessage = messageActivities.pop() === activity;


            const activityHasAttachment = activity.attachments?.length > 0;
            const activityHasSuggestedActions = activity.suggestedActions?.actions?.length > 0 && recentBotMessage

            //validamos si es mensaje del bot con attachment o que tenga acciones sugeridas
            //Si no es mensaje de bot con attachment entonces se retorna sin modificaciones
            if (
              (activityHasAttachment || activityHasSuggestedActions) &&
              activity.type === "message" &&
              activity.from.role === "bot"
            ) {

              //si tiene attachments, validamos si estamos renderizando el contenido de texto o el attachment en si
              // Si se referencia el attachment en si, no se retorna nada.
              //Si se referencia el texto, entonces se genera elemento con contenido de texto  y el/los attachments

              //solo renderizo el "text/markdown" y "text/plain" incluyendo todos los attachments
              if (attachment.contentType === "text/markdown" || attachment.contentType === "text/plain") {

                const textElement = next(card)

                const additionalAttachment = []

                if (activity.attachments?.length > 0) {

                  for (const item of activity.attachments) {
                    console.log(`Rendering ${item.contentType}`)
                    const itemToRender = { activity: activity, attachment: item }
                    const element = next(itemToRender)
                    // const element = renderKnownAttachments(item.contentType, item.content, recentBotMessage)
                    console.log("default render", element)
                    additionalAttachment.push(element)
                  }
                }


                const elementToRender = (
                  (
                    <Bubble key="text" fromUser={false} nub={true}>
                      {textElement}
                      {additionalAttachment}
                      {activityHasSuggestedActions && <SuggestedActions className="acciones-sugeridas" />}
                    </Bubble>
                  )
                )
                return elementToRender

              } else {
                // no se retorna contenido
                return null
              }

            } else {
              return next(card);
            }

          };


          const activityMiddleware = () => next => (...setupArgs) => {
            // https://github.com/microsoft/BotFramework-WebChat/blob/main/samples/05.custom-components/l.disable-adaptive-cards/index.html
            const render = next(...setupArgs);

            if (render) {
              return (...renderArgs) => {
                const element = render(...renderArgs);
                console.log("element activity", element)
                const [card] = setupArgs;
                console.log("renderArgs", renderArgs)
                if (element) {
                  return <div className={card.activity.from.role === 'user' ? 'highlightedActivity--user' : 'highlightedActivity--bot'}>{element}</div>;
                } else {
                  return null
                }


              };
            }

          };



          webChatRef.current = renderWebChat(
            {
              directLine: directLineRef.current,
              //styleSet,
              // activityMiddleware: activityMiddleware,
              attachmentMiddleware: attachmentMiddleware,
              // attachmentMiddleware: customAttachmentMiddleware,
              styleOptions,
              locale,
              className: 'webchat__chat',
              store: chatStore
            },
            document.getElementById('webchat')
          );

        } catch (error) {
          console.error('Error initializing WebChat:', error);
        }
      })();

      return () => {
        if (webChatRef.current) {
          webChatRef.current.store.dispatch({ type: 'WEB_CHAT/DISCONNECT' });
        }
      }
    }
  }, [restartChat]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    //setRemainingChars(256 - value.length);
  };

  const handleSendMessage = () => {

    if (inputValue.trim() && directLineRef.current) {
      directLineRef.current.postActivity({
        type: 'message',
        text: inputValue,
        from: { role: 'user' },
        locale: 'es',
      }).subscribe(
        id => console.log(`Mensaje enviado con id: ${id}`),
        error => console.error('Error al enviar mensaje:', error)
      );
      setInputValue('');
      //setRemainingChars(0);
    }
  };

  return (
    <>
      <div className='container-chat'>

        <div id="webchat" role="main"></div>
        <div className="webchat-input-container">
          <div className="webchat-input-wrapper">
            <Input
              placeholder="Escribe tu mensaje aquí"
              value={inputValue}
              onChange={handleInputChange}
              onPressEnter={handleSendMessage}
              disabled={isInputDisabled}
              className="webchat-input custom-input"
              suffix={
                <Button
                  type="text"
                  icon={<SendOutlined />}
                  onClick={handleSendMessage}
                  disabled={isInputDisabled}
                  className="webchat-send-button"
                />
              }
            />
          </div>
          {/*<div className="webchat-char-count">
          {remainingChars} Caracteres restantes
        </div>*/}
        </div>
      </div>
    </>
  );
};

export default WebChatComponent;
