import { Col, Form, Row, Steps } from 'antd'
import React, { useState, useEffect } from 'react'
import WebChatComponent from '../chat/WebChatComponent'

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { getDepartamentos, getTipoDocumento, getCiudadesByDepartamento } from '../../services/backendService';
import './infoPrevia.scss'

const InformacionPrevia = () => {
  const [form] = Form.useForm()
  const [stepActual, setStepActual] = useState(0)
  const [formIsSubmitting, setFormIsSubmitting] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [restartChat, setRestartChat] = useState(true)
  const [userData, setUserData] = useState(null)
  const [departamentos, setDepartamentos] = useState(null)
  const [tiposDocumento, setTiposDocumento] = useState(null)
  const [ciudades, setCiudades] = useState(null)
  const [isLoadingCiudades, setIsLoadingCiudades] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        Promise.all([getTipoDocumento(), getDepartamentos()]).then((values) => {
          setTiposDocumento(values[0])
          setDepartamentos(values[1])
        });
      }
      catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  const onFinish = async (values) => {
    if (!formIsSubmitting) {
      try {
        let data = { ...values }
        data.Ciudad = ciudades.filter(x => x.value === values.IdCiudad)[0].label
        data.Departamento = departamentos.filter(x => x.value === values.IdDepartamento)[0].label
        data.TipoDocumento = tiposDocumento.filter(x => x.value === values.IdTipoDocumento)[0].label

        setFormIsSubmitting(true)
        setUserData(data)
        setShowChat(true)
      }
      catch (error) {
        console.error('Error submitting form:', error)
      }
      finally {
        setFormIsSubmitting(false)
      }
    }
  }

  const onChangeDeptartamento = async (value) => {
    try {
      setIsLoadingCiudades(true)
      form.resetFields(['Ciudad'])
      const data = await getCiudadesByDepartamento(value)
      setCiudades(data)
    }
    catch (error) {
      console.log(error)
    }
    finally {
      setIsLoadingCiudades(false)
    }
  }


  return (
    <Row className='stepsDiv'>
      <Col span={24}>
        {!showChat ? (
          <>
            <Steps
              className='stepsChat'
              current={stepActual}
              items={[
                {
                  title: '',
                },
                {
                  title: '',
                },
                {
                  title: '',
                },
              ]}
            />
            <Form
              autoComplete='off'
              form={form}
              style={{ width: '100%' }}
              layout='vertical'
              onFinish={onFinish}
            >
              <Step1 setStepActual={setStepActual} form={form} stepActual={stepActual} />
              <Step2 setStepActual={setStepActual} form={form} stepActual={stepActual} tiposDocumento={tiposDocumento} />
              <Step3 ciudades={ciudades} isLoadingCiudades={isLoadingCiudades} onChangeDeptartamento={onChangeDeptartamento} departamentos={departamentos} isSubmiting={formIsSubmitting} setStepActual={setStepActual} form={form} stepActual={stepActual} />
            </Form>
          </>
        ) : (
          <WebChatComponent userData={userData} setRestartChat={setRestartChat} restartChat={restartChat} />
        )}
      </Col>
    </Row>
  )
}

export default InformacionPrevia