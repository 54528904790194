import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Form, Input, Row, Select, Space, InputNumber } from 'antd'
import React from 'react'

const Step2 = ({ setStepActual, form, stepActual, tiposDocumento }) => {

    const onNextPage = async () => {
        form.validateFields(['NombreyApellido', 'TipoDocumento', 'NumeroDocumento'])
            .then((values) => {
                setStepActual(2)
            }).catch((error) => { })
    }
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    return (
        <div style={{ display: `${stepActual === 1 ? '' : 'none'}` }}>
            <Row gutter={[16, 16]} style={{ marginTop: 20, padding: 24 }}>
                <Col span={24}>
                    <Form.Item
                        label="Nombre y Apellidos: "
                        name="NombreyApellido"
                        rules={[
                            {
                                required: true,
                                message: 'Nombre es requerido.'
                            }
                        ]}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input placeholder='Ej: Pepito Pérez' />
                    </Form.Item>
                    <Form.Item
                        label="Tipo de documento: "
                        name="IdTipoDocumento"
                        rules={[
                            {
                                required: true,
                                message: 'Tipo de documento es requerido.'
                            }
                        ]}
                        wrapperCol={{ span: 24 }}
                    >
                        <Select
                            defaultValue="seleccione"
                            style={{
                                width: '100%',
                            }}
                            onChange={handleChange}
                            options={tiposDocumento}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Número de documento: "
                        name="NumeroDocumento"
                        normalize={e => { return e.replace(/\D/g, '') }}
                        rules={[
                            {
                                required: true,
                                message: 'Número de documento es requerido.'
                            }
                        ]}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input placeholder='Ej: 1234567890' />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Flex justify='center'>
                        <Space>
                            <Button style={{ backgroundColor: '#5a6268', color: 'white' }} onClick={() => setStepActual(0)}><ArrowLeftOutlined /></Button>
                            <Button
                                htmlType='button'
                                onClick={() => onNextPage()}
                                shape='round'
                                style={{
                                    minWidth: '200px',
                                    width: '300px',
                                    borderColor: 'transparent',
                                    backgroundColor: '#008296',
                                    color: 'white',
                                }}
                            >Siguiente</Button>
                        </Space>
                    </Flex>
                </Col>
            </Row>
        </div>
    )
}

export default Step2