import { Button, Col, Row, Typography, Space, Checkbox, Form } from 'antd'
import React from 'react'

const { Text, Paragraph } = Typography;

const Step1 = ({ setStepActual, form, stepActual }) => {
    const onNextPage = async () => {
        form.validateFields(['aceptaTerminos'])
            .then((values) => {
                setStepActual(1)
            }).catch((error) => { })

    }

    const onChange = (e) => {
        if (e.target.checked)
            window.open("https://www.colmenaseguros.com/autorizaci%C3%B3n-datos-personales-mari", "mozillaWindow", "popup")
    };

    return (
        <div style={{
            display: `${stepActual === 0 ? '' : 'none'}`,
            height: '100%',
            padding: '20px'
        }}>
            <Row justify="center" align="middle" style={{ height: '100%', padding: 24 }}>
                <Col span={24}>
                    <Paragraph>
                        Antes de iniciar nuestra conversación y buscando garantizarte la seguridad de la información, agradezco nos brindes la autorización del tratamiento de tus datos personales.
                    </Paragraph>
                </Col>
                <Col span={24}>
                    <Form.Item valuePropName="checked" name='aceptaTerminos' rules={[{ required: true, message: "", }, {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject("Debes aceptar los términos y condiciones para acceder."),
                    },]}>
                        <Checkbox onChange={onChange} >Autorizo a Colmena Seguros para el tratamiento de mis datos personales, conforme a las Políticas de Tratamiento De Datos Personales que se encuentran a mi disposición y podrán ser consultadas en la página web.</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <br></br>
                    <Text>Captcha</Text>
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Button
                        htmlType='button'
                        onClick={() => onNextPage()}
                        shape='round'
                        style={{
                            width: '300px',
                            borderColor: 'transparent',
                            backgroundColor: '#008296',
                            color: 'white',
                            marginTop: '20px'
                        }}
                    >
                        Siguiente
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default Step1