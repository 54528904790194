import './App.css';
import Asistente from './components/Asistente';

function App() {
  return (
    <Asistente/>
  );
}

export default App;
