import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Form, Input, Row, Select, Space } from 'antd'
import React, { useState } from 'react'

const Step3 = ({ setStepActual, form, stepActual, isSubmiting, departamentos, onChangeDeptartamento, isLoadingCiudades, ciudades }) => {

    return (
        <div style={{ display: `${stepActual === 2 ? '' : 'none'}` }}>
            <Row style={{ marginTop: 20, padding: 24 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                        label="Correo electrónico: "
                        name="CorreoElectronico"
                        rules={[
                            {
                                required: true,
                                message: 'Correo electrónico es requerido.'
                            },
                            {
                                type: "email",
                                message: "Formato de correo electrónico inválido.",
                            },
                        ]}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input placeholder='Ej: pepito@mail.com' />
                    </Form.Item>
                    <Form.Item
                        label="Número de celular: "
                        normalize={e => { return e.replace(/\D/g, '') }}
                        name="TelefonoCelular"
                        rules={[
                            {
                                required: true,
                                message: 'Número de celular es requerido.'
                            }
                        ]}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input maxLength={10} placeholder='Ej: 3001234567' />
                    </Form.Item>
                    <Row gutter={[10]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                label="Departamento: "
                                name="IdDepartamento"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Departamento es requerido.'
                                    }
                                ]}
                            >
                                <Select
                                    onChange={value => onChangeDeptartamento(value)}
                                    placeholder='Seleccione un departamento'
                                    style={{
                                        width: '100%',
                                    }}
                                    options={departamentos}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                label="Ciudad: "
                                name="IdCiudad"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ciudad es requerido.'
                                    }
                                ]}
                            >
                                <Select
                                    disabled={isLoadingCiudades}
                                    loading={isLoadingCiudades}
                                    placeholder='Seleccione una ciudad'
                                    style={{
                                        width: '100%',
                                    }}
                                    options={ciudades}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Flex justify='center'>
                        <Space>
                            <Button
                                htmlType="button"
                                onClick={() => setStepActual(1)}
                                style={{ backgroundColor: '#5a6268', color: 'white' }}
                            >
                                <ArrowLeftOutlined />
                            </Button>

                            <Button
                                htmlType="submit"
                                shape="round"
                                loading={isSubmiting}
                                style={{
                                    width: '300px',
                                    borderColor: 'transparent',
                                    backgroundColor: '#008296',
                                    color: 'white',
                                }}
                            >
                                Iniciar
                            </Button>
                        </Space>
                    </Flex>
                </Col>
            </Row>
        </div>
    )
}

export default Step3