import { Col, Drawer, Flex, Row, Space, Typography } from 'antd'
import React from 'react'
import marybot from '../../assets/images/mari_bot.b339aea9.jpg'
import { CloseOutlined } from '@ant-design/icons';
import './webChat.css';
import InformacionPrevia from '../clientFormData/InformacionPrevia'

const { Text } = Typography;

const WebChat = ({ isOpen, onClose }) => {

  return (
    <>
      <Drawer
        title={
          <Row align="middle">
            <Col span={4}>
              <img src={marybot} alt='botmary' className='img-bot' style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
            </Col>
            <Col span={18}>
              <Flex justify='center'>
                <Text style={{ color: 'white', fontSize: '1rem', fontWeight: 'bold' }}>Mari</Text>
              </Flex>
              <Flex justify='center'>
                <Text style={{ color: 'white', fontSize: '1rem', fontWeight: 'bold' }}>Asistente Colmena Seguros</Text>
              </Flex>
            </Col>
            <Col span={2}>
              <CloseOutlined style={{ color: 'white', fontSize: '18px' }} onClick={onClose} />
            </Col>
            <Col span={24}>
              <br></br>
            </Col>
          </Row>
        }
        placement="right"
        size={'large'}
        onClose={onClose}
        open={isOpen}
        closable={false}
      >
        <InformacionPrevia />
      </Drawer>
    </>
  )
}

export default WebChat