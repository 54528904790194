import axios from 'axios'
const urlBackend = process.env.REACT_APP_URL_BACKEND_ADMIN

export const getTipoDocumento = async () => {
    return axios.get(`${urlBackend}/api/util/TipoDocumento`).then((res) => { return res.data })
}

export const getDepartamentos = async () => {
    return axios.get(`${urlBackend}/api/util/Departamentos`).then((res) => { return res.data })
}

export const getCiudadesByDepartamento = async (idDepartamento) => {
    return axios.get(`${urlBackend}/api/util/CiudadesByDepartamento/${idDepartamento}`).then((res) => { return res.data })
}